@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
/* APP MOBILE */

.place-card-img-mobile {
    /* width: 299px !important;*/
    height: 225px !important;
    box-shadow: inset 0.5rem .5rem 0.8rem rgba(0,0,0,.40);
    width:100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 15px;
    transition: all 0.5s;
}

.place-card-img-mobile_v2 {
    height: 200px !important;
    box-shadow: inset 0.5rem .5rem 0.8rem rgba(0,0,0,.40);
    width:100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 20%;
    transition: all 0.5s;
}
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5 !important;
}

input,
textarea,

a {
    outline: none !important;
}

input[type=search] {
    border: none;
    box-shadow: 0 2px 10px 0 rgba(181, 181, 181, 0.2);
    border-radius: 2px;
    width: 100%;
    height: 50px;
    padding: 0.6em;
    padding-left: 2.3em;
    background-image: url('https://i.postimg.cc/D0PWrVnn/1382519138.png');
    background-size: 20px;
    background-position: 8px 17px;
    background-repeat: no-repeat;
    margin-top: 0.4em;
}

input[type=search]::-webkit-input-placeholder {
    color: #808080;
    font-weight: 200;
}

input[type=search]::placeholder {
    color: #808080;
    font-weight: 200;
}

::-webkit-scrollbar {
    display: none !important;
}

/* maps marker pin */
.infowindow {
    width: 200px;
    height: 50px;
    background: white;
    border-radius: 4px;
    position: absolute;
    left: 30%;
    top: 40%;
    margin: -20px 0 0 -20px;
}
.pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }

  .pin:after {
    content: "";
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #e6e6e6;
    position: absolute;
    border-radius: 50%;
  }

  .bounce {
    -webkit-animation-name: bounce;
            animation-name: bounce;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
  }

  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
            transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    -webkit-animation: pulsate 1s ease-out;
            animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    -webkit-animation-delay: 1.1s;
            animation-delay: 1.1s;
  }

  @-webkit-keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
              transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1.2, 1.2);
              transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
              transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1.2, 1.2);
              transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }

  @-webkit-keyframes bounce {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px) rotate(-45deg);
              transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
      opacity: 1;
      -webkit-transform: translateY(30px) rotate(-45deg);
              transform: translateY(30px) rotate(-45deg);
    }

    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
              transform: translateY(-10px) rotate(-45deg);
    }

    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
              transform: translateY(0) rotate(-45deg);
    }
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-2000px) rotate(-45deg);
              transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
      opacity: 1;
      -webkit-transform: translateY(30px) rotate(-45deg);
              transform: translateY(30px) rotate(-45deg);
    }

    80% {
      -webkit-transform: translateY(-10px) rotate(-45deg);
              transform: translateY(-10px) rotate(-45deg);
    }

    100% {
      -webkit-transform: translateY(0) rotate(-45deg);
              transform: translateY(0) rotate(-45deg);
    }
  }
/* fine maps marker pin */
#servizi-overlay p {
    margin-bottom: 0 !important;
}

.box_categoria {
    width: 160px !important;
    height: 190px !important;
    box-shadow: inset 0px -50px 50px -30px rgba(0,0,0,0.80);
    box-shadow: 0px 3px 13px 3px #3C3C3C;
    border: solid 1px gray;
}

.box_categoria_v1 {
    width: 110px!important;
    height: 110px!important;
    box-shadow: 0px 0px 20px 4px rgb(181 181 181/20%);
}

.box_categoria_v2 {
    width: 110px!important;
    height: 110px!important;
    box-shadow: 0px 0px 20px 0px rgb(181 181 181/20%);
    border: solid 2px #ae8e53;
}

.box_categoria_kiosk {
    width: 200px!important;
    height: 200px!important;
    box-shadow: 0px 0px 20px 0px rgb(181 181 181/20%);
    border: solid 2px #ae8e53;
}

.icon-delete-img {
    color: white;
    position: absolute;
    background-color: red;
    width: 25px;
    border-radius: 25px;
}

.icon-card-custom {
    padding-right: 0.5em;
    text-align: left;
    /* filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
    width: auto;
    height: 30px;
}

.icon-card-custom_kiosk {
    padding-right: 0.5em;
    text-align: left;
    /* filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
    width: auto;
    height: 50px;
}

#img-logo {
    width: 180px !important;
    padding-top: 3.4em;
    padding-bottom: 1em;
}

.card-custom {
    display: inline-block;
    background-color: white;
    width: auto !important;
    /*height: 48px !important;*/
    border-radius: 2px;
    font-size: 13px;
    margin: 0.5em;
    margin-top: 1em;
    padding: 0.5em;
    box-shadow: 0 2px 10px 0 rgba(181, 181, 181, 0.2);
}

.card-custom:last-child {
    color: rgb(198, 179, 154)
}

.card-lg {
    box-shadow: none;
    background-color: transparent;
    width: 200px !important;
}

.card-img {
    width: 200px;
    height: 230px;
    background-position: center;
    background-size: cover;
}

.card-img-related {
    width: 150px !important;
    height: 80px;
    border-radius: 2px;
    background-position: center;
    background-size: cover;
}

.card-img-related_kiosk {
    width: 200px !important;
    height: 200px;
    border-radius: 15%;
    background-position: center;
    background-size: cover;
}

.card-text {
    margin-top: 0.3em;
    font-size: 15px;
    text-align: left;
    font-weight: 700;
    /* font-size-adjust: 2; */
}

.card-text_kiosk {
    margin-top: 0.3em;
    font-size: 25px;
    text-align: left;
    font-weight: 700;
    /* font-size-adjust: 2; */
}

.card-subtext {
    font-size: 15px;
    text-align: left !important;
    color: #d4d4d5;
}

.card-subtext_kiosk {
    font-size: 25px;
    text-align: left !important;
    color: #d4d4d5;
}

.card-list {
    margin-bottom: 1em;
}

.card-list:last-child {
    margin-bottom: 0;
}

.testimonial-group>.row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.testimonial-group>.row>.col-8,
.testimonial-group>.row>.col-4,
.testimonial-group>.row>.col-5 {
    display: inline-block;
}

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu div {
    display: inline-block;
    text-decoration: none;
}


/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 2; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 5px;
    text-decoration: none;
    font-size: 25px;
    color: #000;
    display: block;
    transition: 0.3s;
}

    /* When you mouse over the navigation links, change their color */
.sidenav a:hover {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

/* right */
/* The side navigation menu */
.sidenavright {
    height: 100%; /* 100% Full-height */
    width: 100%; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 2000; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.3s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenavright .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
/* fine right */


.sidenav-top-box {
    display: block;
    flex-wrap: nowrap;
    min-width: 281px;
}

.sidenav-top {
    display: inline;
    font-size: 1.3em;
}

.sidenav-top-box .col {
    border: 1px solid #f5f5f5;
    padding: 17px 0 17px !important;
}

.sidenav-top-box .row,
.sidenav-top-box .col {
    margin: 0 !important;
    padding: 0;
    flex-wrap: nowrap;
}

.closebtn {
    position: absolute;
    font-size: 30px;
    margin-left: 0.4em;
    color: #000;
    font-weight: 500;
}

.sidenav-top-box i {
    color: rgb(198, 179, 154);
    text-align: center;
    display: block;
}

.sidenav-section i {
    color: rgb(198, 179, 154);
    padding-right: 0.8em;
}

.sidenav-logo {
    height: 160px;
    background-color: #f5f5f5;
    min-width: 281px;
}

.sidenav-logo * {
    width: 281px;
}

/* #main {
    transition: margin-left .5s;
    min-width: 100%;
    min-height: 100%;
    position: relative;
    top: 0;
    bottom: 100%;
    left: 0;
    z-index: 1;
} */

#overlay {
    position: fixed;
    min-height: 100vh !important;
    width: 0;
    z-index: 2;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.527), transparent);
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

/* slide foto posti */
.carousel-indicators .active {
    background-color: #c6b39a !important;
    border: 2px solid white !important;
}

.carousel-indicators li {
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    border: 2px solid white !important;
}

.carousel img {
    /* max-height: 250px; */
    width: 100%;
}

.title-info {
    display: block;
    text-align: center;
    padding: 1em;
    font-size: 1.6em !important;
    font-weight: 400 !important;
}

.title-info_kiosk {
    display: block;
    text-align: center;
    padding: 1em;
    font-size: 2em !important;
    font-weight: 400 !important;
}

.btn-custom {
    background-color: rgb(198, 179, 154) !important;
    border: none !important;
    font-size: 0.8em !important;
    padding: 10px 12px !important;
    font-weight: 300 !important;
    width: 100% !important;
    white-space: nowrap;
}

.btn-custom_kiosk {
    background-color: rgb(198, 179, 154) !important;
    border: none !important;
    font-size: 1.4em !important;
    padding: 10px 12px !important;
    font-weight: 300 !important;
    width: 100% !important;
    white-space: nowrap;
}

.btn-icon {
    font-size: 1.4em !important;
    padding-right: 10px;
}

.small {
    color: #d4d4d5;
    font-size: small;
}

.text-schedule {
    font-size: small;
}

.text-schedule_kiosk {
    font-size: x-large;
}

.fa-clock {
    font-size: 1.2em;
    color: #06d6a0;
}

.border-nav {
    border-bottom: 1px solid #f5f5f5;
}

.search-box {
    position: fixed;
    z-index: 1008;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.74);
    overflow: hidden !important;
}

.search-box .fa-clock {
    font-size: 1.5em;
    color: rgb(198, 179, 154)
}

.text-search-recent {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    font-weight: 600;
}

#search {
    background-image: none;
    height: 50px;
    border-radius: 2px;
}

#search:focus {
    box-shadow: none;
}

.search-x {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 1.5em;
    color: #808080;
    font-weight: 600;
    padding-right: 10px;
    border-right: 1px solid #d4d4d5;
}

.text-search {
    color: #5a6571;
    font-weight: bold;
    font-size: 16px;
}

.search-cat {
    width: auto !important;
    background-color: #d4d4d5 !important;
    border: none !important;
    padding: 10px 20px !important;
    min-width: 100px;
    margin-top: 0.4em !important;
}

.search-cat-box {
    margin: 0 !important;
    display: flex;
    justify-content: space-evenly !important;
}

.recent-box {
    max-height: 200px;
    overflow: auto;
}

.btn-category {
    display: inline-block;
    background-color: #d4d4d5;
    padding: 7px 38px 9px 44px;
    width: 100% !important;
    border-radius: 3px;
    margin-right: 1em;
    overflow: auto;
    min-width: 20% !important;
    width: auto !important;
    margin-top: 1em;
    color: white;
}

.cat-scroll .col-4 {
    width: 100% !important;
}

.btn-category:last-child {
    margin-right: 0 !important;
}

small {
    color: #d4d4d5;
}

.img-spot {
    width: 100%;
}

.price-card {
    font-size: 15px;
    color: #cab397;
}

.price-card_kiosk {
    font-size: 25px;
    color: #cab397;
}

.distance-card {
    font-size: 15px;
    color: #b8b8b8;
}

.distance-card_kiosk {
    font-size: 25px;
    color: #b8b8b8;
}

.green-dot {
    width: 12px;
    height: 12px;
    /* line-height: 200px; */
    /* text-align: center; */
    color: black;
    /* margin: 100px auto; */
    border-radius: 100%;
    background-color: #39da8a;
}

.orange-dot {
    width: 12px;
    height: 12px;
    /* line-height: 200px; */
    /* text-align: center; */
    color: black;
    /* margin: 100px auto; */
    border-radius: 100%;
    background-color: #39da8a;
}

.red-dot {
    width: 12px;
    height: 12px;
    /* line-height: 200px; */
    /* text-align: center; */
    color: black;
    /* margin: 100px auto; */
    border-radius: 100%;
    background-color: #f52061;
}

.text-dot {
    color: #d4d4d5;
    font-size: 14px;
}

.services-icon-bottom {
    width: auto;
    height: 30px;
    /* filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
}

.services-icon-bottom_kiosk {
    width: auto;
    height: 60px;
    /* filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
}

.btn-bottom-info {
    padding: 0.7em !important;
    background-color: white !important;
    color: rgb(198, 179, 154) !important;
    border-color: rgb(198, 179, 154) !important;
}

.btn-bottom-info_kiosk {
    padding: 0.7em !important;
    font-size: 1.3em !important;
    background-color: white !important;
    color: rgb(198, 179, 154) !important;
    border-color: rgb(198, 179, 154) !important;
}

.rate-box {
    float: left;
    margin-top: 0.7em;
    margin-left: 0.8em;
    background-color: white;
    padding: 0.15em;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 4px;
    color: #212529;
    letter-spacing: -0.27px;
    font-size: 15px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.25);
}

.service-box {
    width: 100px;
    height: 100px;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgb(181 181 181 / 20%);
    background-color: #ffffff;
}

.icon-edit {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    color: #d4d4d5;
}

.input-edit {
    border: none;
    border-bottom: 1px solid rgb(198, 179, 154);
    border-radius: 0px;
    width: 78%;
}

.btn-edit {
    font-size: 14px;
    padding: 3px 10px 3px 10px;
    border: none;
    background-color: rgb(198, 179, 154);
    color: white;
    border-radius: 2px;
}

.btn-times {
    position: absolute;
    margin-top: 20px;
    right: 20px;
    font-size: 40px;
    background-color: white;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    z-index: 1001;
}

.btn-times-icon {
    position: absolute;
    margin-left: 11px;
    margin-top: -8px;
}

/* .car-place .carousel img {
    max-height: 300px !important;
    height: 100%;
} */

hr {
    border-top: 1px solid #d4d4d567 !important;
}

.icon-heart {
    float: right;
    font-size: 40px;
    color: rgb(198, 179, 154);
}

.fa-chevron-down {
    color: #d4d4d5 !important;
    padding-left: 0.5em;
    padding-right: 0.2em;
    font-size: 13px;
}

.little-title {
    color: #212529;
    font-weight: 500;
}

.icon-rating {
    font-size: 13px;
    color: #d4d4d5;
}

.rating-active {
    color: rgb(198, 179, 154) !important;
}


.btn-first {
    background-color: rgb(198, 179, 154) !important;
    border-color: rgb(198, 179, 154) !important;
    border-radius: 2px !important;
}


.btn-second {
    background-color: #5a6570 !important;
    border-color: #5a6570 !important;
    border-radius: 2px !important;
}


.btn-light {
    background-color: #d4d4d5 !important;
    border-color: #d4d4d5 !important;
    border-radius: 2px !important;
    color: white !important;
}


.btn-google {
    background-color: #dd4b39 !important;
    border-color: #dd4b39 !important;
    border-radius: 2px !important;
}


.btn-facebook {
    background-color: #0080ff !important;
    border-color: #0080ff !important;
    border-radius: 2px !important;
}

.cookie-box {
    position: fixed;
    z-index: 1002;
    bottom: 30px;
    left: 30px;
    right: 30px;
    background-color: #fff;
    box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.2);
    color: #5d6872;
    font-size: 12px;
    letter-spacing: -0.27px;
}

.cookie-exit {
    font-size: 30px;
    margin-right: 11px;
    float: right;
    color: #d4d4d5;
}

a {
    color: black !important;
    text-decoration: none !important;
}

.text-link {
    text-decoration: underline !important;
}

/* #servizi-overlay {
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 100%;
    height: 0px;
    bottom: 0;
    left: 0;
    transition-duration: .3s;
} */

.overlay-background {
    position: fixed;
    top: 0;
    width: 100vw;
    min-height: 10vh;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.616);
    transition-duration: .3s;
}

.desc-servizio {
    font-size: 14px;
    line-height: 1.33;
    letter-spacing: -0.27px;
    color: #5a6571;
}

.btn-types-active {
    background-color: #5a6570 !important;
    border-color: #5a6570 !important;
}

.price-tag {
    display: block;
    color: #c6b39a;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.47px;
    text-align: right;
}

.price-tag_kiosk {
    display: block;
    color: #c6b39a;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.52;
    letter-spacing: -0.47px;
    text-align: right;
}

.label-lang {
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: -0.27px;
    text-align: center;
    color: #5a6571;
}

.bottom-side {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: -0.27px;
    color: #c7b399;
    margin: 1em;
    margin-top: 2em;
    position: absolute;
    bottom: 20px;
    left: 10px
}

.side-logo {
    width: 130px;
}

.side-logo_kiosk {
    width: 330px;
}

.side-text {
    font-size: 17px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.36px;
    color: #212529;
}

.side-text_kiosk {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.36px;
    color: #212529;
}

.icon-p {
    margin: 0.7em;
    width: 20px;
    height: 24px;
    /*   filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
}

.icon-p_kiosk {
    margin: 0.7em;
    width: 60px;
    height: 60px;
}

.icon-s {
    display: block;
    margin: auto;
    width: 20px;
    height: 20px;
    /*   filter: invert(97%) sepia(6%) saturate(2382%) hue-rotate(315deg) brightness(86%) contrast(76%); */
}


.icon-s_kiosk {
    display: block;
    margin: auto;
    width: 50px;
    height: 50px;
}

.desc-item {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.27px;
    color: #5a6571;
}

.desc-item_kiosk {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.27px;
    color: #5a6571;
}

.btn-plus {
    border: solid 1px #c6b39a;
    padding: 0.5em;
    width: 38px;
    height: 38px;
    color: #c6b39a;
    border-radius: 4px;
    margin: auto;
    font-size: 14px;
}

.btn-plus_kiosk {
    border: solid 1px #c6b39a;
    padding: 0.5em;
    width: 50px;
    height: 50px;
    color: #c6b39a;
    border-radius: 4px;
    margin: auto;
    font-size: 24px;
}

.input-service {
    width: 100%;
    height: 40px;
    border: 1px solid #d4d4d5;
    padding: 7px;
}

.input-service::-webkit-input-placeholder {
    color: #d4d4d5;
    font-size: larger;
}

.input-service::placeholder {
    color: #d4d4d5;
    font-size: larger;
}

.link-color {
    color: rgb(198, 179, 154) !important;
    font-weight: 600;
}

.link-color_kiosk {
    color: rgb(198, 179, 154) !important;
    font-weight: 600;
    font-size: 30px;
}

.order-box {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 75px;
    width: 100%;
    background-color: white;
    box-shadow: 0px -8px 18px 3px rgba(130, 130, 130, 0.86);
    z-index: 1004;
}

.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 90vh;
    width: 100%;
    background-color: white;
    z-index: 1003;
}

.text-placeholder {
    font-size: 18px;
    color: #d4d4d5
}

.text-successo {
    color: #39da8a !important;
}

.text-sospeso {
    color: #759ff1 !important;
}

.text-rifiutato {
    color: #f52061 !important;
}

.circle-text {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    font-size: 17px;
    color: #fff;
    text-align: center;
    background: #5a6570;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label {
    background-color: #5a6570;
    height: 38px;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    border-color: transparent;
    display: flex;
    align-items: center;
}

input[type="radio"]+label {
    width: 100%;
    background-color: #d4d4d5;
    height: 38px;
    cursor: pointer !important;
    border-radius: 2px;
    cursor: default;
    color: #5a6570;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Toastify__close-button {
    background-color: transparent !important;
}

input[type=text] {
    word-wrap: break-word;
    word-break: break-all;
    height: 80px;
}

.font-size-large {
    font-size: 1.3rem;
}

.primary-color {
    color: rgb(198, 179, 154) !important
}

.fotocamera {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    z-index: 100000;
    display: none
}

.fotocamera img {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -45px
}

.sfondo-titolo {
    background: linear-gradient(to right, #E84E1B, #E5007E)
}

.nome-hotel {
    font-size: 2em;
    /*position:absolute;top:15px;*/
    color: #fff;
    padding: 50px 0px;
    background: rgba(0, 0, 0, 0.26);
}

.fa-star {
    background-image: url(/static/media/Star.8e1ba3c5.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
}

.fa-heart {
    background-image: url(/static/media/heart.84ffbce9.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
}

.fa-phone {
    background-image: url(/static/media/phone.22998e60.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
}

.fa-chat {
    background-image: url(/static/media/chat.ea7b3475.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
}

.fa-map {
    background-image: url(/static/media/Map.f4b3313c.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
}

/* 			.sfondo{ background: url({$copertina}) no-repeat center center; background-size: 100%; } */

/* Questionario */

.questionario {
    font-family: 'Rubik', sans-serif;
}

.btn-valutazione {
    background: none;
    background-color: #D2FFE8 !important;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    margin: 4px;
    border: none !important;
    color: #727E8C !important;
}

.btn-valutazione:hover {
    background-color: #39DA8A !important;
    border: none !important;
    color: #FFFFFF !important;
}

.btn-valutazione:focus {
    background-color: #39DA8A !important;
    border: none !important;
    color: #FFFFFF !important;
    box-shadow: none  !important;
}

.text-valutazione {
    font-size: 10px;
    color: #727E8C;
    margin: 10px;
    font-size: 1.5vh;
}
/* Cursori classi */
.pointer {
    cursor: pointer;
}

/* nascondo la checkbox di default */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

.checkmark {
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #EDEDED;
}

.checkbox-label{
    color: #727E8C !important;
}

.checkbox input:checked {
    background-color: #39DA8A;
}

.checkbox-container{
    display: inline;
}

.checkbox input:checked ~ .checkmark {
    background-color: #39DA8A;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

:checked + div {
    font-weight: bold !important;
}

  .label{
      margin-left: 20px;
  }

  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  .btn-invia{
    background-color: #D2FFE8!important;
    height: 38px;
    width: 124px;
    color: #39DA8A !important;
    box-shadow: none !important;
    border: none !important;
  }

  /* spinner classess */

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -25px;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

/* scrollbar quest */

.questionario::-webkit-scrollbar {
    display: block !important;
    -webkit-appearance: none;
    width: 6px  !important;
}

.questionario::-webkit-scrollbar-track {
    box-shadow: none;
  }

 .questionario::-webkit-scrollbar-thumb {
    display: block !important;
    background: #727E8C !important;
    border-radius: 2.5px !important;
  }

  /* Modulo Reputazione */

  .rubik{
    font-family: 'Rubik', sans-serif;
  }

  .hotel-image{
      width: 100%;
      height: 347px;
      background-position-y: center;
      background-position-x: center;
      background-size: cover;
      position: relative;
      resize: horizontal;
      display: flex;
  }

  .fixedIframe{
    margin-top: 26px;
    margin-left: 42px;
    border: none;
    border: 0;
    height: 300px;
    width: 153px;
    z-index: 5;
    position: absolute;
    box-shadow: 0 2px 18px 2px rgb(191 191 191 / 34%);
    background-color: white;
  }

  .widgetBody{
    font-family: 'Rubik','sans-serif';
    font-weight: 400;
    color: #475f7b;
  }

  .overlap-info{
    background-color: #FAF9F8;
    opacity: 0.9;
    width: 100%;
    align-self: flex-end;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
  }

  .header-container{
    flex-direction: column;
    display: flex;
  }

  .hotel-img{
      width: 106px;
      height: 76px;
  }

  .hotel-content p {
    margin-bottom: 0px;
  }

  .right-content{
      background-color: #FAF9F8;
      padding-left: 0px !important;
      border-left: 1px solid #E3E7EA;
  }

  .left-content{
      background-color: white;
  }

  .percentage-row{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding-left: 8%;
      padding-right: 8%;
      flex: 1 1 auto;
  }

  .percentage-row hr {
      flex: 1 1 auto;
  }

  .guest-name {
      font-family: 'Rubik', 'sans-serif';
      font-size: 16px;
      color: #727E8C;
  }

  .percentage {
    font-family: 'Rubik', 'sans-serif';
    color: #F80555;
    font-size: 16px;
  }

  .card-info{
    border-right: 1px solid #C7CFD6;
    border-radius: 4px 0px 0px 4px;
    background-color: #FAF9F8 !important;
  }

  .recensione-info{
      margin-top: 20px;
      flex-direction: column;
  }

  .image-recensione{
    margin-top: 10%;
  }

  .card-recensioni {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: white !important;
  }

  .card-row{
      margin: 0 !important;
  }

  .recensione-info p  {
      margin-bottom: 0 !important;
  }

  .mobile-widget{
      display: none !important;
  }

  .dropdown-ordina{
    display: flex !important;
    justify-content: space-between !important;
    color: #F80555 !important;
    background-color: white !important;
    border: 0.8px solid #F51458 !important;
    border-radius: 4px;
    text-align: left !important;
    font-size: 1.8vh !important;
  }

  .dropdown-ordina, .dropdown-toggle:after{
      align-self: center !important;
  }

  .dropdown-lingue{
    display: flex !important;
    justify-content: space-between !important;
    color: #475F7B !important;
    background-color: white !important;
    border: 0.8px solid #E1E1E1 !important;
    border-radius: 4px;
    text-align: left !important;
    font-size: 1.8vh !important;
  }

  .mg-b-0-center{
      margin-bottom: 0;
      text-align: center;
  }

  /* responsive media query */

  @media (max-width: 767.98px) {
    .hotel-image{
        height: 175px;
    }
    .fixedIframe {
        display: none !important;
    }

    .hotel-content {
        margin-left: 20px !important;
    }

    .hotel-img{
        width: 59px !important;
        height: 42px !important;
    }

    .mobile-widget{
        display: flex !important;
        background-color: #FAF9F8;
        width: 100%;
    }
  }

  /*button style*/
.btn-red {
    font-size: 15px !important;
    color:#FFFFFF !important;
    background-color: #F51458 !important;
}

.btn-red:hover {
    font-size: 15px;
    color: #ff5b5c;
    background-color: #ffdede;
    box-shadow: -1px 2px 6px 0 #ffdede;
}

.active {
    /* background-color: #39DA8A !important; */
    border: none !important;
    color: #FFFFFF !important;
}

.btn-red:active {
    font-size: 15px;
    color: #ff5b5c;
    background-color: #ffdede;
    box-shadow: none;
}

.btn-red:focus {
    font-size: 15px;
    color: #ff5b5c;
    background-color: #ffdede;
    box-shadow: none;
}

.hotel-content{
    margin-left: 234px;
}

.btn-blue{
    background-color: #5A8DEE !important;
    color: white  !important;
    mix-blend-mode: normal !important;
}

.custom-progress{
    width: 100%;
    background-color: white;
    margin-right: .25rem !important;
    border: 0.2px solid #D8D8D8;
    align-items: center;
    border-radius: 4px;
  }

  .custom-progress-head{
      color: #727E8C;
      font-family: 'Rubik, sans-serif';
      margin-top: 8%;
  }

  .progress-bar {
    height: 16px;
    border-radius: 4px;
    background-color: #ff0054 !important;
}

.bg-progress-gray {
    background-color: #e6eef4 !important;
}

.progress-bar-ml {
    border-radius: unset;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.progress-bar-mr {
    border-radius: unset;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.circle{
    width: 50%;
    height: 50%;
    line-height: 50%;
    border-radius: 50%;
    font-size: 17px;
    color: #fff;
    text-align: center;
    background: orange;
}

.circle-text{
    width: calc(50px + 3vw);
    height: calc(50px + 3vw);
    border-radius:50%;
    text-align:center;
    font-size: calc(7px + 0.8vw);
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    background: orange;
    color: white;
}

#box {
    width: 152px;
    height: 295px;
    border-radius: 4px;
    box-shadow: 0 2px 18px 2px rgba(191, 191, 191, 0.34);
    background-color: #ffffff;
}

#dettaglio {
    width: 631px;
    height: 402px;
    border-radius: 4px;
    box-shadow: 0 2px 18px 2px rgba(191, 191, 191, 0.34);
    background-color: #ffffff;
}

.showscroll::-webkit-scrollbar {
    display: block !important;
    -webkit-appearance: none;
    width: 3px  !important;
}

.showscroll::-webkit-scrollbar-track {
    box-shadow: none;
}

.showscroll::-webkit-scrollbar-thumb {
    display: block !important;
    background: #c6b39a !important;
    border-radius: 2px !important;
}

.elchecked {
    background-color: rgb(212, 212, 213);
    border-radius: 4px;
    padding: 4px;
    font-size:14px!important;
    font-weight: bold;
}

@font-face {
    font-family: 'CutyleMonoLine';
    src: url(/static/media/cutyle-mono-line.7a0106c0.ttf) format('truetype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
}

@font-face {
    font-family: 'Cirka';
    src: url(/static/media/PPCirka-Variable.ebe9df07.ttf) format('truetype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
}
@font-face {
    font-family: 'Scto Grotesk A';
    src: url(/static/media/Scto-Grotesk-A-Regular.27ccacf8.otf) format('opentype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
}
/* APP DESKTOP */

/* html,
body {
    height: 100% !important;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#root {
    height: 100%;
} */

.icon-small {
    display: block;
    margin: auto;
    width: 15px;
    height: 15px;
    transition: all 0.2s;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
}

.icon-small:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.nav-right-text {
    font-size: 14px;
    color: #5a6571;
    white-space: nowrap;
}

.first-nav {
    border-bottom: 1px solid #D4D4D5;
}

.first-nav-right {
    transition: all 0.2s;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
}

.first-nav-right:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    font-weight: bold;
}

.second-nav {
    border-bottom: 1px solid #D4D4D5;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: #5a6571;
    z-index: 10;
}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item {
    height: 100%;

}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item>.nav-link {
    color: #5a6571 !important;
}

.second-nav>.navbar-collapse>.navbar-nav>.nav-item:hover {
    font-weight: bold;
    border-bottom: 1px solid #86868b;
}

.nav-item-active {
    font-weight: bold;
    border-bottom: 1px solid #86868b;
    height: 100%;
    align-items: center;
    display: flex;
    color: #C6B39A !important;
}

.nav-item-active a {
    font-weight: bold;
    color: #C6B39A !important;
}

.service-card {
    max-width: 340px;
    box-shadow: 1px 1px 10px 5px #b5b5b5;
    border-radius: 4px;
}

.place-card {
    margin: 10px;
    max-width: 340px;
    box-shadow: 1px 1px 15px 5px #b5b5b5;
    border: 8px solid white;
    border-radius: 4px;
}

.place-card-img {
    /* width: 299px !important;*/
    height: 225px !important;
    /* box-shadow: 0.5rem .5rem 0.8rem rgba(0, 0, 0, .40); */
    width: 100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 4px;
    transition: all 0.5s;
}

.place-card-img:hover {
    /* transition: all 0.5s;
    box-shadow: none; */
}

.place-card-img-shadow {
    background: transparent linear-gradient(180deg, #00000000 70%, #000000 100%) 0% 0% no-repeat padding-box;
    height: 100%;
    border-radius: 4px;
    transition: all 0.5s;
}

.place-card-img-shadow:hover {
    transition: all 0.5s;
    background: linear-gradient(180deg, transparent 70%, transparent 100%) 0% 0% no-repeat padding-box;
}

.circle-categories {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    border-radius: 50%;
    background-position-x: center;
    background-position-y: center;
    /* border: 1px solid; */
    background-size: cover;
}

.home-text {
    /* color: #C5B39A; */
    /* font-size: 28px; */
    font-size: 21px;
    font-weight: 400;
}

.category-group {
    font-size: 17px;
    transition: all 0.5s;
    justify-content: center !important;
}

.category-group:hover {
    transition: all 0.5s;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    font-weight: 800 !important;
    color: black;
    cursor: pointer;
}

.places-container {
    /* overflow-y: scroll;
    height: 68.5vh; */
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

}

.carousel-item-height {
    height: 50vh;
}

/*@media (min-width: 600px){
    .places-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (min-width: 900px) {
    .places-container { grid-template-columns: repeat(3, 1fr); }
}*/


.places-container::-webkit-scrollbar {
    display: block !important;
}

.places-container::-webkit-scrollbar-track {

    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.places-container::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.places-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

.scroll::-webkit-scrollbar {
    display: block !important;
}

.scroll::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

.place-fav {
    width: 43px;
    height: 26px;
    background-color: white;
    border-radius: 5px;
}

.place-text {
    font-size: 18px;
    font-weight: bold;
    width: 291px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.place-distance {
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.place-distance>span {
    font-size: 12px;
}

.price {
    font-weight: bold;
    text-shadow: 0px 2px 6px #000000CC;
    color: white;
}

.services-container {
    padding: 15px;
    border-radius: 15px;
    background-color: #F5F5F5;
}

.fa-heart-desk {
    background-image: url(/static/media/heart.84ffbce9.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-y: center;
    height: 100%;
    background-position-x: center;
}

.navbar-li-container {
    height: 81px;
}

.places-submenu:hover {
    cursor: pointer;
}

.hotel-info-cover {
    height: 350px;
    background-position: center;
    background-size: cover;
}

.hotel-info-main {
    padding: 10px;
}

.hotel-info-container {
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    margin-top: 2em;
    border-radius: 10px;
}

.arrow-right {
    background-color: white;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    right: 14px;
    top: 42px;
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.arrow-left {
    background-color: white;
    position: absolute;
    left: 14px;
    top: 42px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 4px 8px 20px rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.arrow-right>img {
    position: relative;
    left: 4px;
    top: -2px;
}

.arrow-left>img {
    position: relative;
    left: 4px;
    top: -2px;
}

.nav-link {
    color: #5a6571 !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(0.1% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(0.1% + 1px) !important;
}

.cat-scroll::-webkit-scrollbar {
    display: block !important;
}

.cat-scroll::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: #F5F5F5;
}

.cat-scroll::-webkit-scrollbar {
    height: 8px;
    background-color: #F5F5F5;
}

.cat-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D8D8D8;
}

#servizi_desktop::-webkit-scrollbar {
    height: 10px;
    display: block !important;
    border-radius: 10px;
}

/* Track */
#servizi_desktop::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 20px;
}

/* Handle */
#servizi_desktop::-webkit-scrollbar-thumb {
    background: #c6b39a;
    border-radius: 20px;
}

/* Handle on hover */
#servizi_desktop::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.box-servizio {
    height: 225px !important;
    width: 100%;
    height: 100%;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    border-radius: 4px;
    transition: all 0.5s;
    box-shadow: inset 0px -50px 50px -30px rgba(0,0,0,0.80);
}
